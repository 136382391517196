import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Terms and conditions</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner model">
          <section>
            <div className="row">
              <div className="col-12 col-12-medium">
                <p>
                  These terms and conditions outline the rules and regulations
                  for the use of 3D Hearter's Website, located at
                  www.3dhearter.com. By accessing this website you accept these
                  terms and conditions. Do not continue to use 3D Hearter if you
                  do not agree to take all of the terms and conditions stated on
                  this page. These Terms and Conditions were created with the
                  help of the{' '}
                  <a href="https://www.termsandconditionsgenerator.com">
                    Terms And Conditions Generator
                  </a>{' '}
                  and the{' '}
                  <a href="https://www.privacypolicyonline.com/terms-conditions-generator/">
                    Free Terms & Conditions Generator
                  </a>
                </p>

                <h3>
                  <br />
                  <strong>Hyperlinking to 3D Hearter's Content</strong>
                </h3>

                <p>
                  You may link to 3D Hearter's page so long as the link: (a) is
                  not in any way deceptive; (b) does not falsely imply
                  sponsorship, endorsement or approval of the linking party and
                  its products or services; and (c) fits within the context of
                  the linking party’s site.
                </p>

                <p>You may hyperlink to 3D Hearter's Website as follows:</p>

                <ul>
                  <li>By use of 3D Hearter's name; or</li>
                  <li>
                    By use of the uniform resource locator being linked to; or
                  </li>
                  <li>
                    By use of any other description of 3D Hearter's Website
                    being linked to that makes sense within the context and
                    format of content on the linking party’s site.
                  </li>
                </ul>

                <h3>
                  <br />
                  <strong>iFrames</strong>
                </h3>

                <p>
                  Without prior approval and written permission, you may not
                  create frames around 3D Hearter's Webpages that alter in any
                  way the visual presentation or appearance of 3D Hearter's
                  Website.
                </p>

                <h3>
                  <br />
                  <strong>Cookies</strong>
                </h3>

                <p>
                  3D Hearter employs the use of first-party cookies. By
                  accessing 3D Hearter's Website, you agreed to use cookies.
                </p>

                <p>
                  Most interactive websites use cookies to retrieve the user’s
                  details for each visit. Cookies are used by 3D Hearter's
                  website to enable the functionality of certain areas to make
                  it easier for people visiting the website.
                </p>
                <h3>
                  <br />
                  <strong>License</strong>
                </h3>

                <p>
                  Unless otherwise stated, 3D Hearter and/or its licensors own
                  the intellectual property rights for all material on 3D
                  Hearter. All intellectual property rights are reserved. You
                  may access this from 3D Hearter for your own personal use
                  subjected to restrictions set in these terms and conditions.
                </p>
                <p>You must not:</p>
                <ul>
                  <li>Republish material from 3D Hearter</li>
                  <li>Sell, rent or sub-license material from 3D Hearter</li>
                  <li>Reproduce, duplicate or copy material from 3D Hearter</li>
                  <li>Redistribute content from 3D Hearter</li>
                </ul>

                <p>This Agreement shall begin on the date hereof.</p>

                <h3>
                  <br />
                  <strong>Origin of Data</strong>
                </h3>

                <p>
                  All 3D models on 3D Hearter's Website were created based on
                  real patients' imaging methods datasets. These were used with
                  the personal, phone or email agreement with the responsible
                  physicians, radiologiosts or patients themselves. The very
                  first step of this creation process was in every case
                  anonymization of patients' data.
                </p>

                <h3>
                  <br />
                  <strong>Content Liability</strong>
                </h3>

                <p>
                  3D Hearter shall not be hold responsible for any content that
                  appears on your Website. You agree to protect and defend 3D
                  Hearter against all claims that is rising on your Website. No
                  link(s) should appear on any Website that may be interpreted
                  as libelous, obscene or criminal, or which infringes,
                  otherwise violates, or advocates the infringement or other
                  violation of, any third party rights.
                </p>

                <h3>
                  <br />
                  <strong>Reservation of Rights</strong>
                </h3>

                <p>
                  3D Hearter reserve the right to request that you remove all
                  links or any particular link to 3D Hearter's Website. You
                  approve to immediately remove all links to 3D Hearter's
                  Website upon request. 3D Hearter also reserve the right to
                  amen these terms and conditions and it’s linking policy at any
                  time. By continuously linking to 3D Hearter's Website, you
                  agree to be bound to and follow these linking terms and
                  conditions.
                </p>

                <h3>
                  <br />
                  <strong>Removal of links from our website</strong>
                </h3>

                <p>
                  If you find any link on 3D Hearter's Website that is offensive
                  for any reason, you are free to contact and inform 3D Hearter
                  any moment. 3D Hearter will consider requests to remove links
                  but is not obligated to or so or to respond to you directly.
                </p>

                <h3>
                  <br />
                  <strong>Disclaimer</strong>
                </h3>
                <p>
                  As long as the website and the information and services on the
                  website are provided free of charge, 3D Hearter will not be
                  liable for any loss or damage of any nature.
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
